import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

export const authGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  const token = userService.getUserToken();

  if (!!token) {
    return true;
  }

  // if (userService.isLogged()) {
  //   console.log("auth guard", userService.isLogged());
  //   return true;
  // }

  return router.parseUrl('auth/login');
};
